 
h1,
p {
  text-align: center;
}
p {
  width: 100%;
  max-width: 500px;
  margin: auto;
}
a:link,
a:hover,
a:active,
a:visited {
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: #95a5a6;
  text-decoration: none;
}
a:hover {
  color: #7f8c8d;
  text-decoration: underline;
}
.contain {
  width: 100%;
}
.row {
  overflow-x: scroll;
  width: 100%;
 
}
.row__inner {
  -webkit-transition: 450ms -webkit-transform;
  transition: 450ms -webkit-transform;
  transition: 450ms transform;
  transition: 450ms transform, 450ms -webkit-transform;
  font-size: 0;
  white-space: nowrap;
  
   
  max-height: 500px;
  padding-top: 30px;
  padding-bottom: 30px;
   
}
.tile {
  position: relative;
  display: inline-block;
  width: 850px;
  height: 450px;
  margin-right: 15px;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: 450ms all;
  transition: 450ms all;
  -webkit-transform-origin: center left;
          transform-origin: center left;
}

@media (max-width: 1000px) {
  .tile__img {
    width: 350px;
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
       opacity: 0.7;
  }

  .tile {
    position: relative;
    display: inline-block;
    width: 300px;
    height: 400px;
    margin-right: 20px;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition: 450ms all;
    transition: 450ms all;
     
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
  
  .movie-categories-title{
    text-shadow: 3px 3px 5px #000;
    line-height: 1.4em;
    position: relative;
    margin: 0 0 10px 0;
    padding: 0 0 20px 0;
    color:#FFFFFF;
    font-size: 30px;
    margin-top: 20px;
    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word;   
    text-align: center;
  }

  .movie-categories-sinopse-wrapper {
    width: '80%';
    text-shadow: 3px 3px 5px #000;
    text-align: center;
    font-size: 16px;
    color:#FFFFFF;
    display: block;
    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
    word-wrap: break-word;      /* IE */;
    -webkit-line-clamp: 3;

    
  }
  
  .tile__details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    font-size: 10px;
    opacity: 1;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.9)), to(rgba(0,0,0,0)));
    background: linear-gradient(to top, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0) 100%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 450px;
  }
  
}

.tile__img {
  width: 850px;
  height: 450px;
  -o-object-fit: cover;
     object-fit: cover;
     opacity: 0.7;
}

.tile__details:after,
.tile__details:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: #000;
}
 

.tile:hover .tile__details {
  opacity: 1;
}
.tile__title {
  position: absolute;
  bottom: 0;
  padding: 10px;
}
.row__inner:hover {
  /* -webkit-transform: translate3d(-62.5px, 0, 0);
          transform: translate3d(-62.5px, 0, 0); */
}
.row__inner:hover .tile {
  opacity: 1;
}
.row__inner:hover .tile:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 1;
  z-index: 99999;
}
.tile:hover ~ .tile {
  -webkit-transform: translate3d(125px, 0, 0);
          transform: translate3d(125px, 0, 0);
}

.tile__details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 10px;
  opacity: 1;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.9)), to(rgba(0,0,0,0)));
  background: linear-gradient(to top, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0) 100%);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
   
}

.movie-categories {
  font-family: 'Roboto Condensed', sans-serif;
  font-size:14px;
  color:#faa82f;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  margin-left: 10px;
  font-weight: 400;
}

.movie-categories-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
 

}

.movie-categories-title{
  text-shadow: 3px 3px 5px #000;
  line-height: 1.4em;
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 0 20px 0;
 color:#FFFFFF;
 font-size: 30px;
 margin-top: 20px;
}

.movie-categories-sinopse-wrapper {
  width: '80%';
  text-shadow: 3px 3px 5px #000;
  text-align: center;
  font-size: 16px;
  color:#FFFFFF;
  display: block;
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
  white-space: -pre-wrap;     /* Opera <7 */   
  white-space: -o-pre-wrap;   /* Opera 7 */    
  word-wrap: break-word;      /* IE */;
}

.tile__media {
  background: rgba(0,0,0,0.9);
}


.row-wrapper{
   
   background-color: #000;
}

