input.form-payment {
  width: 100% !important;
  background-color: #fff;
  border: 1px solid #ccd0d2;
  color: #555555;
}

.purchase-info {
  margin-top: 5%;
}

.container.section {
  padding-top: 5%;
}
