/* Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.video-container {
	margin-top: 57px;
	background: #000000;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

@media (max-width: 800px) 
{
	.video-playerHLS {
		width: 100%;
		height: 350px;
		align-self: center;
		 
	}
}



@media (min-width: 800px) 
{
	.video-playerHLS {
		width: 95%;
		align-self: center;
		height: 600px
	}
}





.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}

.slick-track:before,
.slick-track:after {
	display: table;
	content: '';
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

[dir='rtl'] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

/* ------ venobox.css --------*/
.vbox-overlay *, .vbox-overlay *:before, .vbox-overlay *:after {
	-webkit-backface-visibility: hidden;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.vbox-overlay {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1040;
	-webkit-transform: translateZ(1000px);
	transform: translateZ(1000px);
	transform-style: preserve-3d;
}

.slick-center {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    transform: scale(1.25);
}
.app-header{
    
    width: 100vw;
    height: 60px;
  }



/* ----- navigation ----- */
.vbox-title {
	width: 100%;
	height: 40px;
	float: left;
	text-align: center;
	line-height: 28px;
	font-size: 12px;
	padding: 6px 40px;
	overflow: hidden;
	position: fixed;
	display: none;
	left: 0;
	z-index: 1050;
}

.vbox-close {
	cursor: pointer;
	position: fixed;
	top: -1px;
	right: 0;
	width: 50px;
	height: 40px;
	padding: 6px;
	display: block;
	background-position: 10px center;
	overflow: hidden;
	font-size: 24px;
	line-height: 1;
	text-align: center;
	z-index: 1050;
}

.vbox-num {
	cursor: pointer;
	position: fixed;
	left: 0;
	height: 40px;
	display: block;
	overflow: hidden;
	line-height: 28px;
	font-size: 12px;
	padding: 6px 10px;
	display: none;
	z-index: 1050;
}
/* ----- navigation ARROWS ----- */
.vbox-next, .vbox-prev {
	position: fixed;
	top: 50%;
	margin-top: -15px;
	overflow: hidden;
	cursor: pointer;
	display: block;
	width: 45px;
	height: 45px;
	z-index: 1050;
}

.vbox-next span, .vbox-prev span {
	position: relative;
	width: 20px;
	height: 20px;
	border: 2px solid transparent;
	border-top-color: #B6B6B6;
	border-right-color: #B6B6B6;
	text-indent: -100px;
	position: absolute;
	top: 8px;
	display: block;
}

.vbox-prev {
	left: 15px;
}

.vbox-next {
	right: 15px;
}

.vbox-prev span {
	left: 10px;
	-ms-transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.vbox-next span {
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	right: 10px;
}
/* ------- inline window ------ */
.vbox-inline {
	width: 420px;
	height: 315px;
	height: 70vh;
	padding: 10px;
	background: #fff;
	margin: 0 auto;
	overflow: auto;
	text-align: left;
}
/* ------- Video & iFrames window ------ */
.venoframe {
	max-width: 100%;
	width: 100%;
	border: none;
	width: 100%;
	height: 260px;
	height: 70vh;
}

.venoframe.vbvid {
	height: 260px;
}

@media (min-width: 768px) {
	.venoframe, .vbox-inline {
		width: 90%;
		height: 360px;
		height: 70vh;
	}

	.venoframe.vbvid {
		width: 640px;
		height: 360px;
	}
}

@media (min-width: 992px) {
	.venoframe, .vbox-inline {
		max-width: 1200px;
		width: 80%;
		height: 540px;
		height: 70vh;
	}

	.venoframe.vbvid {
		width: 960px;
		height: 540px;
	}
}
/* 
Please do NOT edit this part! 
or at least read this note: http://i.imgur.com/7C0ws9e.gif
*/
.vbox-open {
	overflow: hidden;
}

.vbox-container {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;
	z-index: 20;
	max-height: 100%;
}

.vbox-content {
	text-align: center;
	float: left;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: 20px 10px;
}

.vbox-container img {
	max-width: 100%;
	height: auto;
}

.figlio {
	box-shadow: 0 0 12px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	max-width: 100%;
	text-align: initial;
}

img.figlio {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.vbox-content.swipe-left {
	margin-left: -200px !important;
}

.vbox-content.swipe-right {
	margin-left: 200px !important;
}

.animated {
	webkit-transition: margin 300ms ease-out;
	transition: margin 300ms ease-out;
}

.animate-in {
	opacity: 1;
}

.animate-out {
	opacity: 0;
}
/* ---------- preloader ----------
 * SPINKIT 
 * http://tobiasahlin.com/spinkit/
-------------------------------- */
.sk-double-bounce,.sk-rotating-plane {
	width: 40px;
	height: 40px;
	margin: 40px auto;
}

.sk-rotating-plane {
	background-color: #333;
	-webkit-backface-visibility: visible;
	-moz-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
	animation: sk-rotatePlane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotatePlane {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		transform: perspective(120px) rotateX(0) rotateY(0);
	}

	50% {
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
	}

	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

@keyframes sk-rotatePlane {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		transform: perspective(120px) rotateX(0) rotateY(0);
	}

	50% {
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
	}

	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

.sk-double-bounce {
	position: relative;
}

.sk-double-bounce .sk-child {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #333;
	opacity: .6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
	animation: sk-doubleBounce 2s infinite ease-in-out;
}

.sk-double-bounce .sk-double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-doubleBounce {
	0%,100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes sk-doubleBounce {
	0%,100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.sk-wave {
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;
	margin: 40px auto;
}

.sk-wave .sk-rect {
	background-color: #333;
	height: 100%;
	width: 4px;
	margin: 0 1px;
	display: inline-block;
	-webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
	animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect1 {
	-webkit-animation-delay: -1.2s;
	animation-delay: -1.2s;
}

.sk-wave .sk-rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.sk-wave .sk-rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.sk-wave .sk-rect4 {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s;
}

.sk-wave .sk-rect5 {
	-webkit-animation-delay: -.8s;
	animation-delay: -.8s;
}

@-webkit-keyframes sk-waveStretchDelay {
	0%,100%,40% {
		-webkit-transform: scaleY(.4);
		transform: scaleY(.4);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

@keyframes sk-waveStretchDelay {
	0%,100%,40% {
		-webkit-transform: scaleY(.4);
		transform: scaleY(.4);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
}

.sk-three-bounce {
	margin: 40px auto;
	width: 100px;
	text-align: center;
}

.sk-three-bounce .sk-child {
	width: 16px;
	height: 16px;
	background-color: #333;
	border-radius: 100%;
	margin: 4px;
	display: inline-block;
	-webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
	animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-cube-grid,.sk-spinner-pulse {
	width: 40px;
	height: 40px;
	margin: 40px auto;
}

.sk-three-bounce .sk-bounce1 {
	-webkit-animation-delay: -.32s;
	animation-delay: -.32s;
}

.sk-three-bounce .sk-bounce2 {
	-webkit-animation-delay: -.16s;
	animation-delay: -.16s;
}

@-webkit-keyframes sk-three-bounce {
	0%,100%,80% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes sk-three-bounce {
	0%,100%,80% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.sk-spinner-pulse {
	background-color: #333;
	border-radius: 100%;
	-webkit-animation: sk-pulseScaleOut 1s infinite ease-in-out;
	animation: sk-pulseScaleOut 1s infinite ease-in-out;
}

@-webkit-keyframes sk-pulseScaleOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes sk-pulseScaleOut {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
	}
}

.sk-cube-grid .sk-cube {
	width: 33.33%;
	height: 33.33%;
	background-color: #333;
	float: left;
	-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s;
}

.sk-cube-grid .sk-cube2 {
	-webkit-animation-delay: .3s;
	animation-delay: .3s;
}

.sk-cube-grid .sk-cube3 {
	-webkit-animation-delay: .4s;
	animation-delay: .4s;
}

.sk-cube-grid .sk-cube4 {
	-webkit-animation-delay: .1s;
	animation-delay: .1s;
}

.sk-cube-grid .sk-cube5 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s;
}

.sk-cube-grid .sk-cube6 {
	-webkit-animation-delay: .3s;
	animation-delay: .3s;
}

.sk-cube-grid .sk-cube7 {
	-webkit-animation-delay: 0ms;
	animation-delay: 0ms;
}

.sk-cube-grid .sk-cube8 {
	-webkit-animation-delay: .1s;
	animation-delay: .1s;
}

.sk-cube-grid .sk-cube9 {
	-webkit-animation-delay: .2s;
	animation-delay: .2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
	0%,100%,70% {
		-webkit-transform: scale3D(1,1,1);
		transform: scale3D(1,1,1);
	}

	35% {
		-webkit-transform: scale3D(0,0,1);
		transform: scale3D(0,0,1);
	}
}

@keyframes sk-cubeGridScaleDelay {
	0%,100%,70% {
		-webkit-transform: scale3D(1,1,1);
		transform: scale3D(1,1,1);
	}

	35% {
		-webkit-transform: scale3D(0,0,1);
		transform: scale3D(0,0,1);
	}
}

.sk-wandering-cubes {
	margin: 40px auto;
	width: 40px;
	height: 40px;
	position: relative;
}

.sk-wandering-cubes .sk-cube {
	background-color: #333;
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
	animation: sk-wanderingCube 1.8s ease-in-out -1.8s infinite both;
}

.sk-wandering-cubes .sk-cube2 {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s;
}

@-webkit-keyframes sk-wanderingCube {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	25% {
		-webkit-transform: translateX(30px) rotate(-90deg) scale(.5);
		transform: translateX(30px) rotate(-90deg) scale(.5);
	}

	50% {
		-webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
		transform: translateX(30px) translateY(30px) rotate(-179deg);
	}

	50.1% {
		-webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
		transform: translateX(30px) translateY(30px) rotate(-180deg);
	}

	75% {
		-webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5);
		transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5);
	}

	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@keyframes sk-wanderingCube {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	25% {
		-webkit-transform: translateX(30px) rotate(-90deg) scale(.5);
		transform: translateX(30px) rotate(-90deg) scale(.5);
	}

	50% {
		-webkit-transform: translateX(30px) translateY(30px) rotate(-179deg);
		transform: translateX(30px) translateY(30px) rotate(-179deg);
	}

	50.1% {
		-webkit-transform: translateX(30px) translateY(30px) rotate(-180deg);
		transform: translateX(30px) translateY(30px) rotate(-180deg);
	}

	75% {
		-webkit-transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5);
		transform: translateX(0) translateY(30px) rotate(-270deg) scale(.5);
	}

	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

/** Contents

    -- Body
    -- Headings
    -- Sections
    -- Hero
    -- Content header
    -- Scroll navigation
    -- Nav
    -- Dropdowns
    -- Buttons
    -- Movie slides
    -- Tabs
    -- Pill tabs
    -- Accordion
    -- Pill accordion
    -- Single gallery
    -- Call to Action
    -- News
    -- Comments
    -- Pagination
    -- Sidebar
    -- Single movie
    -- Show times
    -- Movie ifo
    -- Maps
    -- Icon box
    -- Form
    -- News carousel
    -- Footer
    -- Keyframes

**/

/**  Body  **/


::-moz-selection {
	color: #fff;
	background: #e76115;
}

::selection {
	color: #fff;
	background: #e76115;
}

html,
body {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 17px;
	height: 100%;
	color: #000000;
	text-shadow: 1px 1px 1px rgba(0,0,0,.004);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a,
a:active,
a:visited {
	cursor: pointer;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	text-decoration: none;
	color: #e76115;
	outline: none;
	box-shadow: none;
}

a:hover {
	text-decoration: none;
	color: #ba9a56;
}

a:active,
a:focus {
	outline: none;
	box-shadow: none;
}

p {
	margin: 0 0 20px 0;
}

p:last-child {
	margin-bottom: 0;
}

img {
	max-width: 100%;
	height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.4em;
	position: relative;
	margin: 0 0 10px 0;
	padding: 0 0 20px 0;
	border-bottom: 1px solid #d8d8d8;
}

h2:after,
h3:after,
h4:after,
h5:after {
	position: absolute;
	right: auto;
	bottom: -1px;
	display: block;
	width: 125px;
	height: 4px;
	content: '';
	background-image: linear-gradient(to right, #faa82f, #e76115);
}

h2.no-underline,
h3.no-underline,
h4.no-underline,
h5.no-underline {
	padding: 0;
	border-bottom: none;
}

h2.no-underline:after,
h3.no-underline:after,
h4.no-underline:after,
h5.no-underline:after {
	display: none;
}

h2 {
	font-size: 18px;
	margin-bottom: 40px;
	letter-spacing: 1.7px;
	text-transform: uppercase;
	color: #e76115;
}

h3 {
	font-size: 24px;
	font-weight: 300;
	letter-spacing: 1px;
	color: #101010;
}

span.title {
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #faa82f;
}

*:focus {
	outline: none;
	box-shadow: none;
}

blockquote {
	font-size: 18px;
	position: relative;
	min-height: 135px;
	margin: 45px 0;
	padding: 30px 30px 30px 135px;
	color: #4a4a4a;
	border: solid 1px #d8d8d8;
}

blockquote:before {
	font-family: 'Material Icons';
	font-size: 24px;
	font-size: 50px;
	font-style: normal;
	line-height: 1;
	line-height: 75px;
	position: absolute;
	top: 30px;
	left: 30px;
	display: inline-block;
	overflow: hidden;
	width: 24px;
	width: 75px;
	height: 24px;
	height: 75px;
	content: '\e244';
	text-align: center;
	text-transform: none;
	color: #fff;
	border-radius: 50%;
	background-color: #e76115;
	text-rendering: optimizeLegibility;
	font-feature-settings: 'liga' 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input:focus,
input:active,
textarea:focus,
textarea:active,
select:focus,
select:active {
	border-color: #faa82f;
	box-shadow: 0 0 15px rgba(251, 189, 97, .4);
}

hr.space-10 {
	display: block;
	height: 10px;
	margin: 0;
	border: none;
}

i.material-icons {
	display: inline-flex;
	vertical-align: middle;
}

.wrapper {
	overflow-x: hidden;
}

hr.space-40 {
	height: 40px;
	margin: 0;
	border: none;
}

/**  Headings  **/

.heading .tel,
.heading .search {
	font-size: 14px;
	font-weight: bold;
	display: flex;
	float: right;
	margin-top: 30px;
	color: #fff;
}

.heading .tel {
	margin-right: 15px;
}

.heading .search {
	padding-left: 15px;
	border-left: 1px dotted rgba(255, 255, 255, .5);
}

.heading .tel i,
.heading .search i {
	font-size: 17px;
	max-width: 17px;
	margin-right: 5px;
	color: #fff;
}

.heading .tel a,
.heading .search a {
	color: #fff;
}

/**  Sections  **/

.section {
	padding-top: 35px;
	padding-bottom: 35px;
}

.section.negative-margin {
	margin-top: -140px;
}

.section.small-padding {
	padding: 60px 0;
}

.border-top {
	border-top: 1px solid #d8d8d8;
}

.dark {
	position: relative;
	color: #fff;
	background: #101010;
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
	z-index: 1;
	margin-bottom: 60px;
	color: #fff;
	border-bottom-color: #333;
}

.remove-top-padding {
	padding-top: 0;
}

.remove-bottom-padding {
	padding-bottom: 0;
}

.right-align {
	text-align: right;
}

/**  Hero  **/

#hero {
	overflow: hidden;
	min-height: 600px;
	padding-top: 89px;
}

#hero .scroll,
#content_hero .scroll {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 50%;
	margin-left: -45px;
	-webkit-animation: ScrollDown 1.5s infinite;
	-moz-animation: ScrollDown 1.5s infinite;
	-o-animation: ScrollDown 1.5s infinite;
	animation: ScrollDown 1.5s infinite;
}

#hero .carousel-inner,
#hero .carousel-inner .item {
	height: 100%;
	min-height: 600px;
	text-align: center;
	background-color: #000;
	background-position: center;
	background-size: cover;
}

#hero .carousel-inner .item:before,
#content_hero:before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	content: '';
	background-image: -webkit-gradient(
    linear,
    right bottom,
    right top,
    color-stop(0, rgba(0, 0, 0, 0)),
    color-stop(1, rgb(0, 0, 0))
    );
	background-image: -o-linear-gradient(top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
	background-image: -moz-linear-gradient(top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
	background-image: -webkit-linear-gradient(top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
	background-image: -ms-linear-gradient(top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
	background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}

#hero .container {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	margin: auto;
	-webkit-transform: none;
	-moz-transform: none;
	transform: none;
	align-items: center;
	justify-content: center;
}

#hero .container .blurb,
#content_hero .container .blurb {
	position: relative;
	width: 100%;
    /*margin-top: 50px;*/
	-webkit-animation: Blurb .5s .5s forwards;
	-moz-animation: Blurb .5s .5s forwards;
	-o-animation: Blurb .5s .5s forwards;
	animation: Blurb .5s .5s forwards;
	text-align: left;
	opacity: 0;
}

#hero .container .blurb h1,
#content_hero .container .blurb h1 {
	font-size: 52px;
	font-weight: 300;
	padding: 0;
	-webkit-animation: Heading .5s .5s forwards;
	-moz-animation: Heading .5s .5s forwards;
	-o-animation: Heading .5s .5s forwards;
	animation: Heading .5s .5s forwards;
	letter-spacing: 3px;
	opacity: 0;
	color: #fff;
	border: none;
}

#hero .container .blurb .buttons {
	-webkit-animation: Buttons .5s .5s forwards;
	-moz-animation: Buttons .5s .5s forwards;
	-o-animation: Buttons .5s .5s forwards;
	animation: Buttons .5s .5s forwards;
	opacity: 0;
}

#hero .container .blurb p,
#content_hero .container .blurb p {
	font-size: 18px;
	color: #fff;
}

#hero .container .blurb .certificate,
#content_hero .container .blurb .certificate {
	font-size: 13px;
	font-weight: bold;
	line-height: 48px;
	display: inline-block;
	width: 48px;
	height: 48px;
	margin-right: 8px;
	transform: translateY(2px);
	text-align: center;
	color: #fff;
	border: solid 2px #fff;
	border-radius: 50%;
}

/* Fade transition for carousel items */

.carousel-fade .carousel-inner .item {
	transition-property: opacity;
	opacity: 0;
}

.carousel-fade .carousel-inner .active {
	opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
	z-index: 1;
	left: 0;
	opacity: 0;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
}

.carousel-fade .carousel-control {
	z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
	.carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 0;
	}

	.carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 0;
	}

	.carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

/* Carousel indicators */

.carousel-indicators {
	position: absolute;
    /*bottom: 60px;*/
	left: 0;
	width: 100%;
	margin-left: 30px;
	text-align: left;
}

.carousel-indicators li,
.carousel-indicators li.active {
	width: 10px;
	height: 10px;
	margin: 0 8px 0 0;
	border: solid 2px #fff;
}

/**  Content header  **/

#content_hero {
	position: relative;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	min-height: 600px;
	padding-top: 95px;
}

#content_hero:before {
	top: auto;
	height: 70%;
}

#content_hero .container .blurb {
	margin-top: 200px;
	margin-bottom: 100px;
}

#content_hero .container .blurb h1 {
	-webkit-animation: none;
	-moz-animation: none;
	-o-animation: none;
	animation: none;
	opacity: 1;
}

#content_hero .buttons .btn {
	margin-right: 15px;
}

/**  Scroll navigation  **/

.navbar.banner--clone {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	background: #101010;
}

.navbar.banner--clone .nav li.dropdown:hover .dropdown-menu {
	transform: translateY(9px);
}

.navbar.banner--clone .nav {
	border-bottom: none;
}

.navbar.banner--clone .nav li:after,
.navbar.banner--clone .nav li.active a:after {
	bottom: -11px;
}

.navbar.banner--clone .heading {
	display: none;
}

.navbar.banner--clone .logo img {
	max-height: 40px;
	margin: 15px 0;
}

.navbar.banner--clone .nav li a {
	font-size: 12px;
}

.navbar.banner--clone .nav li.active a {
	color: #faa82f;
}

.navbar.banner--stick {
	-webkit-transform: translateY(0%);
	-ms-transform: translateY(0%);
	transform: translateY(0%);
}


/**  Navigation  **/

.logo {
	position: relative;
	z-index: 1;
	display: inline-block;
}

.navbar-toggle {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	transform: rotate(0deg);
}

.navbar-toggle.minimize {
	transform: rotate(90deg);
}

.navbar {
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
	left: 0;
	border-radius: 0;
	background: black;
}

.navbar-collapse {
	position: relative;
	padding: 0;
}

.nav {
	position: absolute;
	top: 30px;
	right: 0;
	float: none;
	border-bottom: 1px solid rgba(255, 255, 255, .2);
}

.nav li {
	position: relative;
	margin: 0;
}

.nav li a {
	font-size: 16px;
	font-weight: normal;
	line-height: .8em;
	display: inline-block;
	padding: 20px 24px;
	letter-spacing: 3px;
	text-transform: uppercase;
	color: #fff;
}

.nav li:focus a,
.nav li:active a,
.nav li a:focus,
.nav li a:active {
	background: transparent;
}

.nav li a:hover {
	background: transparent;
}

.nav li:after {
	position: absolute;
	right: auto;
	bottom: 0;
	left: 0;
	display: block;
	width: 0;
	height: 2px;
	content: '';
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
	background-image: linear-gradient(to right, #faa82f, #e76115);
	box-shadow: 0 0 20px 0 #faa82f;
}

.nav li:hover:after {
	right: 0;
	width: 100%;
}

.nav li.active a {
	color: #fff;
}

.nav li.active a:after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 2px;
	content: '';
	background-image: linear-gradient(to right, #faa82f, #e76115);
	box-shadow: 0 0 20px 0 #faa82f;
}

.navbar-toggle .icon-bar {
	background: #faa82f;
}

/**  Dropdown  **/

.nav .dropdown-menu {
	z-index: 0;
	display: block;
	visibility: hidden;
	width: 100%;
	padding: 0;
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	transform: scale(1.1);
	opacity: 0;
	border: none;
	border-radius: 0;
	background-image: linear-gradient(to right, #faa82f, #e76115);
}

.nav li.dropdown:hover .dropdown-menu {
	z-index: 10;
	visibility: visible;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

.nav .dropdown-menu li {
	margin: 0;
	padding: 0;
}

.nav .dropdown-menu li a {
	font-size: 13px;
	display: block;
	height: auto;
	padding: 15px 10px;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
}

.nav .dropdown-menu li a:hover,
.nav .dropdown-menu li.active a {
	color: #fff;
	background: rgba(255, 255, 255, .1);
}

.nav .dropdown-menu li:after {
	display: none;
}

/** Buttons **/

.btn {
	font-size: 13px;
	font-weight: normal;
	position: relative;
	overflow: hidden;
	width: auto;
	padding: 12px 24px;
	letter-spacing: 4px;
	text-transform: uppercase;
	color: #fff;
	border-width: 2px;
	border-radius: 30px;
}

.btn i,
.btn:hover i {
	position: relative;
	z-index: 1;
	display: inline-flex;
	vertical-align: middle;
	color: #fff;
}

.btn-default {
	border: none;
	background-image: linear-gradient(to right, #faa82f, #e76115);
}

#imagePosterTester:hover  {
	opacity: 0.5;
	transition: 0.5s ease-in-out;
	background-color: red;
}


.poster-image-container {
	position: relative;
	width: 100%;
	height:300px;
	display:flex;
	justify-content:center;
	align-items:stretch;
	background-color:'#e76115'
}
.poster-image-container:hover .poster-image-container-overlay {
	opacity: 1;
  }
  
  .poster-image-container:hover img {
	filter:grayscale(100%)
  }

.poster-image-container-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: 0.75s ease;
	background-color: transparent;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
}


.movie-category-wrapper {
	display:flex;
	flex-direction:row;
	align-items:stretch;
	justify-content:space-between;
	flex-wrap:wrap;
	scroll-behavior: smooth;
	 
}

.loader {
	border: 6px solid #f3f3f3; /* Light grey */
	border-top: 6px solid orange; /* Blue */
	border-radius: 50%;
	width: 	30px;
	height: 30px;
	animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }

@media (max-width: 600px) {
	.movie-category-wrapper {
		display:flex;
		flex-direction:column;
		justify-content:space-between;
		align-items:center;
		 
	}
	.movie-category-wrapper-item, .category-show-more {
		margin-top:20px;
	}
}

 
.ps-react-tabs .ps-react-tab-payment-method {
	display:none
}

.ps-react-tabs .ps-react-tab-payment-method:nth-child(2) {
	display:block
}

.btn-default span,
.btn-default span:hover,
.btn-ghost span:hover {
	position: relative;
	z-index: 1;
	color: #fff;
}

.btn-default:hover:before {
	width: 140%;
}

.btn-default:before,
.btn-ghost:before {
	position: absolute;
	z-index: 0;
	bottom: 0;
	left: -15px;
	width: 0;
	height: 100%;
	content: '';
	-webkit-transition: .4s;
	transition: .4s;
	-webkit-transform: skewX(30deg);
	-ms-transform: skewX(30deg);
	transform: skewX(30deg);
	background: #e76115;
}

.btn-primary {
	border: none;
	background: #e76115;
}

.btn-ghost {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	color: #e76115;
	border-color: #e76115;
	background: transparent;
}

.btn-ghost:hover span {
	color: #fff;
}

.btn-ghost:hover:before {
	z-index: -1;
	width: 140%;
}

.btn-ghost:hover {
	color: #fff;
}

button.btn-default,
button.btn-primary {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

button.btn-default:before,
button.btn-primary:before {
	display: none;
}

button.btn-default:hover,
button.btn-primary:hover {
	color: #fff;
	background: #faa82f;
}

a.arrow-button {
	font-size: 12px;
	position: relative;
	letter-spacing: 4px;
	text-transform: uppercase;
	color: #e76115;
}

a.arrow-button:after {
	font-family: 'Material Icons';
	font-weight: bold;
	position: absolute;
	top: -1px;
	content: 'chevron_right';
	text-transform: none;
	-webkit-font-feature-settings: 'chevron_right';
}

a.arrow-button:hover {
	color: #101010;
}

/**  Movies slider  **/

.slick-list {
	margin: 0 -15px;
}

.slick-slide {
	margin: 0 15px;
	text-align: center;
}

.slick-slider .slick-arrow {
	font-size: 40px;
	position: absolute;
	top: 35%;
	cursor: pointer;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	background: -webkit-linear-gradient(#faa82f, #e76115);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.slick-slider .slick-arrow.left {
	left: -65px;
}

.slick-slider .slick-arrow.right {
	right: -65px;
}

.slick-slider .slick-arrow.left:hover {
	left: -60px;
	opacity: .6;
}

.slick-slider .slick-arrow.right:hover {
	right: -60px;
	opacity: .6;
}

.slick-slide .movie-poster {
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	border-radius: 10px;
}

.slick-slide .movie-poster img {
	-webkit-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
}

.slick-slide .movie-poster:hover img {
	filter: grayscale(100%);
}

.slick-slide .movie-poster:before {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: -100px;
	width: 0;
	height: 100%;
	content: '';
	-webkit-transition: .5s;
	transition: .5s;
	-webkit-transform: skewX(30deg);
	-ms-transform: skewX(30deg);
	transform: skewX(30deg);
	opacity: .9;
	background-image: linear-gradient(to bottom, #faa82f, #e76115);
}

.slick-slide .movie-poster:hover:before {
	width: 200%;
}

.slick-slide .movie-poster:hover aside {
	transform: scale(1);
	opacity: 1;
}

.slick-slide .movie-poster aside {
	position: absolute;
	z-index: 1;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-transition: .3s;
	transition: .3s;
	-webkit-transition-delay: .1s;
	transition-delay: .1s;
	transform: scale(.9);
	text-align: center;
	opacity: 0;
	align-items: center;
}

.slick-slide .movie-poster aside div {
	width: 100%;
}

.slick-slide .movie-poster aside .play {
	font-size: 32px;
	line-height: 70px;
	display: block;
	width: 80px;
	height: 80px;
	margin: 0 auto 20px auto;
	color: #fff;
	color: #fff;
	border: solid 2px #fff;
	border-radius: 50%;
}

.slick-slide .movie-poster aside .play:hover {
	transform: scale(.9);
}

.slick-slide .movie-poster aside .read-more {
	font-size: 13px;
	display: block;
	margin-bottom: 10px;
	cursor: pointer;
	letter-spacing: 4px;
	text-transform: uppercase;
	color: #fff;
}

.slick-slide .movie-poster aside .date {
	font-size: 16px;
	display: block;
	color: #fff;
}

.star-rating {
	display: inline-block;
}

.star-rating i {
	font-size: 18px;
	display: inline-block;
	width: 18px;
	color: #faa82f;
}

.star-rating i.grey {
	color: #d8d8d8;
}

.movie-slide h4 {
	font-size: 18px;
	color: #4a4a4a;
}

/**  Tabs  **/

.tabs ul {
	font-size: 0;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #d8d8d8;
}

.tabs ul li {
	display: inline-block;
}

.tabs ul li a {
	font-size: 18px;
	position: relative;
	display: block;
	padding: 10px 30px 15px 30px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #4a4a4a;
}

.tabs ul li.ui-state-active a {
	color: #e76115;
}

.tabs ul li a:after {
	position: absolute;
	bottom: -1px;
	left: 0;
	display: block;
	width: 0;
	height: 4px;
	content: '';
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out;
	background-image: linear-gradient(to right, #faa82f, #e76115);
}

.tabs ul li a:hover:after,
.tabs ul li.ui-state-active a:after {
	width: 100%;
}

.tabs > div {
	padding: 45px 0;
}

.tabs ul li.date {
	float: right;
}

.tabs ul li.date span {
	font-size: 14px;
	display: block;
	padding: 10px 0;
	padding-right: 0;
	cursor: default;
	text-align: right;
	text-transform: uppercase;
	color: #000000;
}

/**  Pill tabs  **/

.tabs.pill-style ul {
	border: none;
	border-radius: 30px;
	background: #f5f5f5;
}

.tabs.pill-style ul li a {
	padding: 10px 40px;
	text-decoration: none;
	border-radius: 30px;
}

.tabs.pill-style ul li a:after {
	display: none;
}

.tabs.pill-style ul li.ui-state-active a {
	color: #fff;
	background-image: linear-gradient(to right, #faa82f, #e76115);
}

/**  Accordion  **/

.accordion h2,
.accordion h3,
.accordion h4 {
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 20px;
	padding: 0 15px;
	padding-bottom: 10px;
	cursor: pointer;
	text-transform: uppercase;
	color: #4a4a4a;
}

.accordion h2:after,
.accordion h3:after,
.accordion h4:after {
	display: none;
}

.accordion h2.ui-state-active,
.accordion h3.ui-state-active,
.accordion h4.ui-state-active {
	color: #e76115;
}

.accordion h2.ui-state-active:before,
.accordion h3.ui-state-active:before,
.accordion h4.ui-state-active:before {
	content: '\f068';
}

.accordion h2:before,
.accordion h3:before,
.accordion h4:before {
	font-family: FontAwesome;
	font-size: 12px;
	position: absolute;
	right: 15px;
	display: inline-block;
	content: '\f067';
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.accordion div {
	padding: 30px 15px;
}

/**  Pill accordion  **/

.accordion.pill-style h2,
.accordion.pill-style h3,
.accordion.pill-style h4 {
	margin: 0;
	padding: 15px;
	color: #000000;
	background: #f5f5f5;
}

.accordion.pill-style h2:before,
.accordion.pill-style h3:before,
.accordion.pill-style h4:before {
	line-height: 27px;
	width: 25px;
	height: 25px;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	background: #717171;
}

.accordion.pill-style h2.ui-state-active:before,
.accordion.pill-style h3.ui-state-active:before,
.accordion.pill-style h4.ui-state-active:before {
	background-image: linear-gradient(to bottom, #faa82f, #e76115);
}

/* Live search */

.live-search {
	position: relative;
	margin-bottom: 20px;
	margin-top: 20px;
	padding: 20px;
	border-radius: 30px 30px 0 0;
	background: #fff;
}

.live-search input {
	width: 100%;
	padding: 10px 20px;
	border: solid 1px #d8d8d8;
	border-radius: 30px;
}

.live-search input:focus {
	box-shadow: none;
}

.live-search i {
	font-size: 21px;
	position: absolute;
	top: 35px;
	right: 35px;
	color: #e76115;
}

/* Movie tabs */

.movie-tabs {
	margin-bottom: 40px;
	padding-bottom: 40px;
	border-bottom: 1px solid #d8d8d8;
}

.movie-tabs:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.movie-tabs img {
	border-radius: 10px;
}

.movie-tabs span.title {
	color: #e76115;
}

.movie-tabs p {
	max-width: 625px;
}

.movie-tabs .viewing-times {
	font-size: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #4a4a4a;
}

.movie-tabs .viewing-times i {
	font-size: 13px;
}

.movie-tabs .time {
	font-size: 14px;
	display: inline-block;
	margin-left: 5px;
	padding: 7px 10px;
	letter-spacing: 2px;
	color: #000000;
	border-radius: 5px;
	background-color: #d8d8d8;
}

.movie-tabs .time.past {
	opacity: .5;
}

.movie-tabs .running-time {
	font-size: 12px;
	text-align: right;
	letter-spacing: 1.5px;
	text-transform: uppercase;
}

.movie-tabs .certificate {
	font-size: 13px;
	font-weight: bold;
	line-height: 33px;
	display: inline-block;
	width: 33px;
	height: 33px;
	margin-left: 5px;
	text-align: center;
	letter-spacing: 0;
	color: #fff;
	border-radius: 50%;
	background: #4a4a4a;
}

/* Coming soon slider */

.comingSoon-slides > div {
	position: static;
}

.comingSoon-slides .single-slide {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	height: 0;
	padding-bottom: 40px;
	opacity: 0;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	align-items: center;
}

.comingSoon-slides span.title {
	color: #e76115;
}

.comingSoon-slides h3 {
	font-size: 34px;
	margin-bottom: 0;
}

.comingSoon-slides .star-rating {
	margin-right: 8px;
}

.comingSoon-slides .star-rating i {
	font-size: 14px;
	width: 14px;
}

.comingSoon-slides .single-slide p:first-of-type {
	margin-top: 20px;
}

.date {
	font-size: 14px;
	display: inline-block;
	opacity: .75;
	color: #fff;
}

.date i {
	font-size: 14px;
	margin-right: 5px;
}

.comingSoon-slides .bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.comingSoon-slides .slide-content,
.comingSoon-slides .slide-video {
	position: relative;
	z-index: 1;
}

.comingSoon-slides .video {
	position: relative;
}

.comingSoon-slides .video {
	display: block;
	-webkit-box-shadow: 0 0 50px 15px rgba(0,0,0,.5);
	-moz-box-shadow: 0 0 50px 15px rgba(0,0,0,.5);
	box-shadow: 0 0 50px 15px rgba(0,0,0,.5);
}

.comingSoon-slides .video i {
	font-size: 50px;
	line-height: 75px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 80px;
	height: 80px;
	margin: auto;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	text-align: center;
	text-indent: 14px;
	color: #fff;
	border: 2px solid #e76115;
	border-radius: 50%;
}

.comingSoon-slides .video:hover i {
	transform: scale(.9);
}

.comingSoon-slides a.arrow-button:hover {
	color: #faa82f;
}

#comingSoon {
	padding: 60px 0;
}

#comingSoon:before {
	position: absolute;
	right: 0;
	bottom: 0;
	left: -250%;
	display: block;
	width: 500%;
	height: 100%;
	padding: 55px 0;
	content: '';
	background: #101010;
}

#comingSoon div {
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

#comingSoon .slick-slide:hover {
	cursor: pointer;
	opacity: 1 !important;
}

#comingSoon .slick-slide img {
	border-radius: 10px;
}

#comingSoon .slick-slide h5 {
	font-size: 16px;
	overflow: hidden;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 0;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
}

#comingSoon .slick-slide .release-date {
	font-size: 14px;
	opacity: .5;
	color: #fff;
}

.comingSoon-slides .slick-slider {
	background: #000;
}

/**  Single gallery  **/

.singleGallery .slide-video img {
	width: 100%;
}

.singleGallery .slick-slider .slick-arrow {
	top: 45%;
}

/**  Call to action  **/

.cta {
	text-align: center;
}

.cta p {
	font-size: 23px;
	font-weight: 300;
	margin-bottom: 10px;
	text-align: center;
	letter-spacing: 1px;
	color: #4a4a4a;
}

.cta p:last-of-type {
	margin-bottom: 0;
}

.cta .gradient-text {
	font-size: 46px;
	font-weight: 400;
}

.gradient-text {
	color: transparent;
	background: linear-gradient(330deg, #faa82f 0%, #e76115 100%);
	-webkit-background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/**  News  **/

.news article {
	margin-bottom: 60px;
	padding-bottom: 60px;
	border-bottom: solid 1px #d8d8d8;
}

.news article:last-of-type {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.news article .categories {
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #e76115;
}

.news article h2 {
	font-size: 24px;
	font-weight: 300;
	margin-bottom: 20px;
	text-transform: none;
	color: #101010;
}

.news article .movie-detail-img {
	margin-bottom: 40px;
	border-radius: 10px;
}

.news article .img {
	position: relative;
	display: block;
	overflow: hidden;
	margin-bottom: 40px;
	border-radius: 10px;
}

.news article .img:before {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: -100px;
	width: 0;
	height: 100%;
	content: '';
	-webkit-transition: .5s;
	transition: .5s;
	-webkit-transform: skewX(30deg);
	-ms-transform: skewX(30deg);
	transform: skewX(30deg);
	opacity: .9;
	background-image: linear-gradient(to bottom, #faa82f, #e76115);
}

.news article .img aside {
	position: absolute;
	z-index: 1;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-transition: .3s;
	transition: .3s;
	-webkit-transition-delay: .1s;
	transition-delay: .1s;
	transform: scale(.9);
	text-align: center;
	opacity: 0;
	align-items: center;
}

.news article .img aside div {
	width: 100%;
}

.news article .img aside i {
	font-size: 32px;
	line-height: 80px;
	display: block;
	width: 80px;
	height: 80px;
	margin: 0 auto 20px auto;
	color: #fff;
	color: #fff;
	border: solid 2px #fff;
	border-radius: 50%;
}

.news article .img aside .date {
	font-size: 16px;
	display: block;
	color: #fff;
}

.news article .img:hover:before {
	width: 200%;
}

.news article .img:hover aside {
	transform: scale(1);
	opacity: 1;
}

.bordered {
	overflow: hidden;
	margin-top: 35px;
	padding: 10px 0;
	border-top: solid 1px #d8d8d8;
	border-bottom: solid 1px #d8d8d8;
}

.single-tags {
	float: left;
}

.single-tags i {
	font-size: 14px;
	margin-right: 10px;
	color: #e76115;
}

.single-tags a {
	font-size: 12px;
	display: inline-block;
	margin: 0 0 0 5px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #000000;
}

.single-tags a:hover {
	color: #e76115;
}

.social-share {
	float: right;
}

.social-share a {
	font-size: 12px;
	text-transform: uppercase;
	color: #e76115;
}

.social-share a i {
	font-size: 16px;
	margin-left: 5px;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

.social-share a:hover,
.social-share a:hover i {
	color: #faa82f;
}

.social-share .share {
	margin: 0;
}

.social-share .share a {
	line-height: 32px;
	width: 34px;
	height: 33px;
	margin: 0;
}

.social-share .share i {
	font-size: 14px;
	margin: 0;
}

/**  Comments  **/

.news-comments,
.news-comment-form {
	margin-top: 75px;
}

.news-comments h3,
.news-comment-form h3 {
	font-size: 18px;
	margin-bottom: 45px;
	text-transform: uppercase;
	color: #e76115;
}

.single-comment {
	position: relative;
	margin-bottom: 40px;
	margin-left: 34px;
	padding: 30px 30px 30px 60px;
	background-color: #f3f3f3;
}

.single-comment > img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: -34px;
	max-width: 68px;
	height: auto;
	margin: auto;
	border: 3px solid #fff;
	border-radius: 50%;
}

.single-comment .date {
	font-size: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #e76115;
}

.single-comment h4 {
	color: #4a4a4a;
}

/**  Pagination  **/

ul.pagination {
	width: 100%;
	margin: 0;
	text-align: center;
}

ul.pagination li {
	display: inline-block;
	margin: 0 4px;
}

ul.pagination li a,
ul.pagination li span {
	line-height: 32px;
	position: relative;
	width: 48px;
	height: 48px;
	color: #4a4a4a;
	border: solid 2px #e8e8e8;
	border-radius: 50%;
}

ul.pagination li a:hover {
	color: #e76115;
	border-color: #e76115;
	background: transparent;
}

ul.pagination li span:hover {
	background: transparent;
}

ul.pagination li:first-of-type a,
ul.pagination li:last-of-type a {
	width: auto;
	padding-right: 20px;
	padding-left: 20px;
	border-radius: 30px;
}

ul.pagination li a.active {
	border-color: #e76115;
}

/**  Sidebar  **/

.sidebar .widget {
	position: relative;
	margin-bottom: 40px;
}

.sidebar .widget .search + i {
	font-size: 16px;
	position: absolute;
	top: 17px;
	right: 15px;
	color: #e76115;
}

.sidebar .widget h3 {
	font-size: 18px;
	margin-bottom: 25px;
	text-transform: uppercase;
	color: #e76115;
}

.sidebar .widget ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sidebar .widget ul li {
	margin-bottom: 8px;
}

.sidebar .widget ul li a {
	display: block;
	color: #000000;
}

.sidebar .widget ul li a:hover {
	text-indent: 5px;
	color: #e76115;
}

.sidebar .widget .tag {
	font-size: 14px;
	display: inline-block;
	margin: 0 2px 5px 0;
	padding: 5px 8px;
	letter-spacing: 2px;
	color: #000000;
	border: 2px solid #d8d8d8;
	border-radius: 5px;
	background-color: #d8d8d8;
}

.sidebar .widget .tag:hover {
	color: #e76115;
	border-color: #e76115;
	background: transparent;
}

/**  Single movie  **/

.single-movie .poster {
	display: block;
	margin: 0 auto 25px auto;
	border-radius: 10px;
}

.share {
	margin-bottom: 25px;
	text-align: center;
}

.share a {
	line-height: 40px;
	display: inline-block;
	width: 43px;
	height: 43px;
	margin: 0 2px;
	text-align: center;
	border: solid 2px #d8d8d8;
	border-radius: 50%;
}

.share a:hover {
	color: #faa82f;
	border-color: #faa82f;
}

/**  Show times  **/

ul.show-times {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.show-times li {
	display: table;
	margin-bottom: 10px;
}

ul.show-times li i {
	font-size: 12px;
	font-style: normal;
	display: table-cell;
	width: 75px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #4a4a4a;
}

ul.show-times li.today i {
	color: #e76115;
}

ul.show-times li .time {
	font-size: 14px;
	display: inline-block;
	margin: 0 5px 5px 0;
	padding: 7px 10px;
	letter-spacing: 2px;
	color: #000000;
	border-radius: 5px;
	background-color: #d8d8d8;
}

ul.show-times li .time.past {
	opacity: .5;
}

/**  Movie info  **/

ul.movie-info {
	margin: 25px 0;
	padding: 0;
	list-style: none;
}

ul.movie-info li {
	font-size: 12px;
	margin-bottom: 10px;
	letter-spacing: 1px;
}

ul.movie-info li i {
	font-size: 12px;
	font-style: normal;
	display: inline-block;
	width: 115px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #4a4a4a;
}

/* Icon row */

.icon-row {
	display: table;
	margin-bottom: 30px;
}

.icon-row .col {
	display: table-cell;
	vertical-align: top;
}

.icon-row .col:first-of-type {
	padding-right: 25px;
}

.icon-row span {
	display: block;
	width: 75px;
	height: 75px;
	text-align: center;
	border: solid 2px #d8d8d8;
	border-radius: 50%;
}

.icon-row span i {
	font-size: 32px;
	position: relative;
	top: 50%;
	max-width: 46px;
	transform: translateY(-50%);
	color: #e76115;
}

.icon-row h2,
.icon-row h3,
.icon-row h4 {
	margin-bottom: 5px;
	padding-bottom: 0;
	color: #4a4a4a;
	border: none;
}

.icon-row h2:after,
.icon-row h3:after,
.icon-row h4:after {
	display: none;
	margin: 0;
	padding: 0;
}

.contact .icon-row span {
	width: 100px;
	height: 100px;
}

.contact .icon-row span i {
	font-size: 46px;
}

.contact .icon-row .col {
	vertical-align: middle;
}

/**  Icon box  **/

.icon-box {
	text-align: center;
}

.icon-box i {
	font-size: 36px;
	max-width: 36px;
	max-height: 46px;
	margin: 0 auto 25px auto;
	word-wrap: normal;
	color: #e76115;
}

.icon-box h2,
.icon-box h3,
.icon-box h4 {
	margin-bottom: 10px;
	padding-bottom: 0;
	text-transform: uppercase;
	color: #4a4a4a;
	border: none;
}

.icon-box h2:after,
.icon-box h3:after,
.icon-box h4:after {
	display: none;
}

/* Map */

#map {
	height: 450px;
	margin-bottom: 30px;
	border: 10px solid #fff;
	border-radius: 30px 30px 0 0;
}

/**  Form  **/

form label {
	font-size: 12px;
	font-weight: normal;
	display: block;
	margin-bottom: 5px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #4a4a4a;
}

.labelTerms {
    display: inline-block !important;
	margin-left: 0.5rem;
}

.checkTerms {
	width: auto !important;
}

form input,
form textarea {
	width: 100%;
	padding: 12px 15px;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	border: 1px solid #d8d8d8;
}

.form-group.half {
	float: left;
	width: 50%;
}

.form-group.half:first-of-type {
	padding-right: 15px;
}

.form-group.half:last-of-type {
	padding-left: 15px;
}

.form-group.half:after {
	display: table;
	clear: both;
	content: '';
}

/**  Comments  **/

.comments {
	position: relative;
	overflow-y: scroll;
	height: 480px;
}

.comments .row {
	margin: 0;
}

.comments::-webkit-scrollbar {
	-webkit-appearance: none;
}

.comments::-webkit-scrollbar:vertical {
	width: 6px;
	border-radius: 6px;
	background: #f0f0f0;
}

.comments::-webkit-scrollbar:horizontal {
	height: 6px;
	border-radius: 6px;
	background: #f0f0f0;
}

.comments::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background-image: linear-gradient(to bottom, #faa82f, #e76115);
}

.comments .row {
	margin-bottom: 35px;
}

.comments .date {
	font-size: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #e76115;
}

/**  News carousel  **/

.news-carousel .slick-slide {
	text-align: left;
}

.news-carousel .slick-arrow {
	top: 25%;
}

.news-carousel h2,
.news-carousel h3,
.news-carousel h4 {
	margin-bottom: 15px;
	padding-bottom: 0;
	text-align: left;
	border: none;
}

.news-carousel h2:after,
.news-carousel h3:after,
.news-carousel h4:after {
	display: none;
	text-align: left;
	border: none;
}

.news-carousel .date {
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #e76115;
}

.news-carousel p {
	text-align: left;
}

/**  Footer  **/

footer {
	z-index: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 75px 0 0 0;
	color: #fff;
	background: #292929;
}

footer * {
	font-size: 14px;
	font-weight: 500;
}

footer ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

footer ul li a,
footer ul li a:active,
footer ul li a:visited {
	font-size: 13px;
	display: inline-block;
	padding: 3px 0;
	letter-spacing: 1.5px;
	color: #fff;
}

footer ul li a:hover {
	color: #faa82f;
}

footer h6 {
	font-size: 16px;
	margin: 0;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #e76115;
	border: none;
}

footer .fa {
	display: inline-block;
	width: 25px;
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	transform: translateY(2px);
	color: rgba(255, 255, 255, .3);
}

footer ul li a:hover .fa {
	color: #faa82f;
}

footer div[class^='col'] {
	position: relative;
}

footer .copyright {
	margin-top: 50px;
	padding: 20px 0;
	border-top: 1px solid rgba(255, 255, 255, .1);
}

footer .copyright a {
	color: #fff;
}

footer .copyright a:hover {
	color: #e76115;
}


/**  Keyframes  **/

@-webkit-keyframes Heading {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@-moz-keyframes Heading {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@-o-keyframes Heading {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes Heading {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes ScrollDown {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(3px);
	}

	100% {
		transform: translateX(0);
	}
}

@-moz-keyframes ScrollDown {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(3px);
	}

	100% {
		transform: translateX(0);
	}
}

@-o-keyframes ScrollDown {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(3px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes ScrollDown {
	0% {
		transform: translateX(0);
	}

	50% {
		transform: translateX(3px);
	}

	100% {
		transform: translateX(0);
	}
}

@-webkit-keyframes Buttons {
	0% {
		transform: translateY(60px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@-moz-keyframes Buttons {
	0% {
		transform: translateY(60px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@-o-keyframes Buttons {
	0% {
		transform: translateY(60px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes Buttons {
	0% {
		transform: translateY(60px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@-webkit-keyframes Blurb {
	0% {
		transform: scale(1.15);
		opacity: 0;
	}

	100% {
		transform: translateY(1);
		opacity: 1;
	}
}

@-moz-keyframes Blurb {
	0% {
		transform: scale(1.15);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-o-keyframes Blurb {
	0% {
		transform: scale(1.15);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes Blurb {
	0% {
		transform: scale(1.15);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@media (max-width: 991px) {
	.logo {
		max-width: 180px;
	}

	.nav li a {
		font-size: 14px;
		padding: 20px 14px;
		letter-spacing: 2px;
	}

	.tabs ul li a {
		font-size: 16px;
	}

	.tabs ul li:last-of-type {
		display: none;
	}

	ul.show-times li i {
		display: block;
		margin-bottom: 5px;
	}

	ul.show-times li .time {
		padding: 5px 6px;
	}

	.single-movie .share a {
		font-size: 12px;
		line-height: 28px;
		width: 30px;
		height: 30px;
	}

	.slick-slider .slick-arrow.left {
		left: -45px;
	}

	.slick-slider .slick-arrow.right {
		right: -45px;
	}
}

@media (max-width: 767px) {
	.logo {
		max-width: 150px;
		margin-left: 15px;
	}

	.navbar-toggle {
		margin-top: 15px;
	}

	.nav {
		position: static;
		margin: 0 !important;
		background: rgba(0, 0, 0, .9);
	}

	.nav li {
		text-align: center;
	}

	.nav li:first-of-type a {
		padding-top: 25px;
	}

	.nav li:last-of-type a {
		padding-bottom: 25px;
	}

	.nav li.active a:after {
		display: none;
	}

	.nav li.active a {
		color: #e76115;
	}

	.nav li a {
		display: block;
		padding: 15px;
	}

	.nav .dropdown-menu li a {
		font-size: 12px;
	}

	.movie-tabs img {
		margin-bottom: 30px;
	}

	.comments img {
		margin-bottom: 15px;
	}

	form .right-align {
		text-align: left;
	}

	.icon-row:first-of-type {
		margin-top: 40px;
	}

	.sidebar {
		margin-top: 60px;
	}

	footer .col-sm-3 {
		margin-bottom: 30px;
	}
}

@media (max-width: 600px) {
	#hero .container .blurb h1,
    #content_hero .container .blurb h1 {
		font-size: 40px;
		line-height: 1.2em;
	}

	#content_hero .star-rating {
		margin-top: 10px;
	}

	.slick-slide .movie-poster:before {
		-webkit-transform: skewX(23deg);
		-ms-transform: skewX(23deg);
		transform: skewX(23deg);
	}

	.slick-slide img,
    .movie-tabs img {
		width: 100%;
	}

	.tabs ul {
		border-bottom: none;
	}

	.tabs ul li {
		display: block;
	}

	.tabs ul li a {
		padding: 10px 30px;
	}

	.tabs ul li a:after {
		width: 4px;
		height: 0;
		background-image: linear-gradient(to bottom, #faa82f, #e76115);
	}

	.tabs ul li a:hover:after,
    .tabs ul li.ui-state-active a:after {
		width: 4px;
		height: 100%;
	}

	.tabs ul li:last-of-type {
		display: block;
		float: none;
	}

	.tabs ul li:last-of-type span {
		text-align: left;
		text-indent: 30px;
	}

	.movie-tabs .viewing-times {
		display: block;
		margin-bottom: 25px;
	}

	.movie-tabs .running-time {
		padding-top: 10px;
		text-align: left;
	}

	.comingSoon-slides .single-slide {
		display: block;
		overflow: hidden;
	}

	.comingSoon-slides .video {
		margin: 40px 0 0 0;
	}

	.single-movie .poster {
		width: 100%;
	}

	.single-movie .share {
		text-align: left;
	}

	.single-movie .share a {
		font-size: 18px;
		line-height: 40px;
		width: 43px;
		height: 43px;
	}

	.icon-row {
		width: 100%;
		text-align: center;
	}

	.icon-row .col {
		display: block;
	}

	.icon-row .col:first-of-type {
		padding: 0;
	}

	.icon-row .col .circle {
		margin: 0 auto 25px auto;
	}

	ul.pagination li {
		display: none;
	}

	ul.pagination li:first-of-type,
    ul.pagination li:last-of-type {
		display: inline-block;
	}

	.single-tags {
		float: none;
		margin-bottom: 10px;
	}

	.social-share {
		float: none;
	}

	.social-share .share {
		text-align: left;
	}

	.form-group.half {
		float: none;
		width: 100%;
	}

	.form-group.half:first-of-type,
    .form-group.half:last-of-type {
		padding: 0;
	}

	.icon-box {
		margin-bottom: 30px;
	}

	.tabs.pill-style ul li a {
		border-radius: 0;
	}

	.tabs.pill-style ul li:first-of-type a {
		border-radius: 30px 30px 0 0;
	}

	.tabs.pill-style ul li:last-of-type a {
		border-radius: 0 0 30px 30px;
	}
}