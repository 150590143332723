.faq-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.faq-item {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
     
}

.faq-item span {
    
    text-align: justify;
     
}