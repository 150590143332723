/* styles.css */

  /*
  fallback color
  https://stackoverflow.com/questions/29307993/csslint-warning-fallback-background-hex-or-rgb-should-precede-rgba-background
  https://css-tricks.com/rgba-browser-support/
  https://github.com/CSSLint/csslint/wiki/require-fallback-colors

  This could also be written as:
  color: #000000;
  color: rgba(0, 0, 0, 0.8);
  Mas, irá mostrar um fundo laranja no botão ao invés de preto.

  */

#loginModal {
	background: #000000;
 /* The Fallback */
	background: rgba(0, 0, 0, 0.8);
}

.modal-content {
	padding: 1.2rem;
}

#image {
	width: 92%;
	min-height: 400px;
	margin: auto;
}

#image img {
	width: 100%;
	height: 100%;
}

/* register.css */

.form-register {
	width: 100% !important;
	background-color: #fff;
	border: 1px solid #ccd0d2;
	color: #555555;
}

/* payment.css */

.form-payment {
	width: 100% !important;
	background-color: #fff;
	border: 1px solid #ccd0d2;
	color: #555555;
}

.purchase-info {
	margin-top: 5%;
}

.container.section {
	padding-top: 5%;
}

/* login.css */

.btn.btn-primary.btn.btn-secondary.btn-login {
	margin-left: 43%;
}

.form-login {
	width: 420px !important;
	background-color: #fff;
	border: 1px solid #ccd0d2;
	color: #555555;
}