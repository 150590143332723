.navbar {
	padding-left: 21px;
}

.nav {
	top: 0px;
}

#menu-primary > li > a {
	font-size: 12px;
}

#menu-primary > li {
	max-height: 49px;
}

#menu-primary.nav {
	right: auto;
	left: 230px;
	border-bottom: none;
}

.collapse button.btn {
	top: 5px;
}

@media (max-width: 415px) {
	.navbar {
		z-index: 999;
		padding-left: 0;
		/*padding-bottom: 20px;*/
	}
	
	.navbar .dropdown-menu {
		background-image: linear-gradient(to right, #faa82f, #e76115);
	}
	
	.navbar form {
		width: 100%;
	}
	
	.navbar form .input-group {
		margin: 0 auto;
	}
	
	#menu-primary > li {
		max-height: initial;
	}
	
	.collapse button.btn {
		margin: 20px 115px 20px 0;
	}
}