button.btn.btn-primary.btn.btn-secondary.btn-login {
  margin-left: 43%;
}

input.form-login {
  width: 420px !important;
  background-color: #fff;
  border: 1px solid #ccd0d2;
  color: #555555;
}
