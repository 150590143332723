#hero {
	padding-top: 50px;
}

#loginModal.modal.fade.in {
    background: #000000; /* fallback color */
	background: rgba(0, 0, 0, .5);
}

.modal-backdrop {
	display: none;
}

body.modal-open {
	overflow: auto !important;
}

body.modal-open[style] {
	padding-right: 0 !important;
}

.arrow.play {
	font-size: 50px;
	line-height: 75px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 80px;
	height: 80px;
	margin: auto;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	text-align: center;
	text-indent: 14px;
	color: #fff;
	border: 2px solid #e76115;
	border-radius: 50%;
}

.shadow-text {
	text-shadow: 3px 3px 5px #000;
}

.black-layer {
    background: #000000; /* fallback color */
	background: rgba(0, 0, 0, .2);

}

.bounce-enter-active {
	animation: bounce-in .5s;
}

.bounce-leave-active {
	animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(1);
	}
}

.certificate {
	font-size: 16px !important;
	font-weight: 700;
	line-height: 48px;
	display: inline-block;
	width: 48px;
	height: 48px;
	margin-right: 8px;
	transform: translateY(2px);
	text-align: center;
	color: #fff;
	border: 0 solid #fff !important;
	border-radius: 50%;
}

.certificate-0 {
	background-color: #0ea447;
}

.certificate-10 {
	background-color: #0095de;
}

.certificate-12 {
	background-color: #ffca00;
}

.certificate-14 {
	background-color: #f98300;
}

.certificate-16 {
	background-color: #ef2517;
}

.certificate-18 {
	background-color: #000;
}