
.contain {
  width: 100%;
}
.row {
  overflow: scroll;
  width: 100%;
}
.row_og_inner {
  -webkit-transition: 450ms -webkit-transform;
  transition: 450ms -webkit-transform;
  transition: 450ms transform;
  transition: 450ms transform, 450ms -webkit-transform;
  font-size: 0;
  white-space: nowrap;
  margin: 50.3125px 0;
  padding-bottom: 10px;
}
.tile_og {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 400.625px;
  margin-right: 25px;
  margin-left: 25px;
  font-size: 20px;
  
  cursor: pointer;
  -webkit-transition: 450ms all;
  transition: 450ms all;
  -webkit-transform-origin: center left;
  transform-origin: center left;
  
  
}
.tile_og_img {
  width: 150px;
  height: 240.625px;
  -o-object-fit: contain;
     object-fit: contain;
}
.tile_og_details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 10px;
  opacity: 1;
  
  -webkit-transition: 450ms opacity;
  transition: 450ms opacity;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tile_og_details:after,
.tile_og_details:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: #000;
}
 
.tile_og_details:before {
  
  left: 0;
  width: 100%;
  font-size: 30px;
  margin-left: 7px;
  margin-top: -18px;
  text-align: center;
  z-index: 2;
}
.tile_og:hover .tile_og_details {
  opacity: 1;
}
.tile_og_title {
  position: absolute;
  bottom: 0;
  padding: 10px;
}
.row_og_inner:hover {
  -webkit-transform: translate3d(-62.5px, 0, 0);
          transform: translate3d(-62.5px, 0, 0);
}
.row_og_inner:hover .tile {
  opacity: 0.3;
}
.row_og_inner:hover .tile_og:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  opacity: 1;
}
.tile_og:hover ~ .tile {
  -webkit-transform: translate3d(125px, 0, 0);
          transform: translate3d(125px, 0, 0);
}





.movie-categories-og {
  font-family: 'Roboto Condensed', sans-serif;
  font-size:14px;
  color:#faa82f;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: block;
  margin-left: 10px;
  font-weight: 400;
}

.movie-categories-wrapper-og {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
 

}

.movie-categories-title-og{
  text-shadow: 3px 3px 5px #000;
  line-height: 1.4em;
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 0 20px 0;
 color:#FFFFFF;
 font-size: 14px;
  
}

.movie-categories-sinopse-wrapper-og {
  width: '80%';
  text-shadow: 3px 3px 5px #000;
  text-align: center;
  font-size: 12px;
  color:#FFFFFF;
  display: block;
  white-space: pre-wrap;      /* CSS3 */   
  white-space: -moz-pre-wrap; /* Firefox */    
  white-space: -pre-wrap;     /* Opera <7 */   
  white-space: -o-pre-wrap;   /* Opera 7 */    
  word-wrap: break-word;      /* IE */;
}

.tile_og_media {
  background: rgba(16,16,16,0.9);
}


.row-wrapper{
   
   background-color: #101010;
}

h3{
  background-color: transparent;
}


div {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
div::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
