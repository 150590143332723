#loginModal {
  background: rgba(0, 0, 0, 0.8);
}
.modal-content {
  padding: 1.2rem;
}

#image {
  width: 92%;
  min-height: 400px;
  margin: auto;
}

#image img {
  width: 100%;
  height: 100%;
}

.newsContainer {
  display: flex;
}

.coverContainer {
  width: 200px;
  height: 200px;
  overflow: hidden;
  flex: 0 0 200px;
  margin-right: 2rem;
}

.coverContainer img {
  position: relative;
  width: 200px;
  min-width: 200px;
  height: auto;
  max-height: 100%;
}

.titleNews {
  font-size: 1.4rem;
  color: #e76115 !important;
}

.newsInfoContainer .newsResume {
  overflow:hidden;
  text-overflow: ellipsis;    
  display: -webkit-box;    
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
  word-wrap:break-word;    
  line-height:1.5;   
  max-height:4.5rem; 
}

.newsResume p {
  max-width: 100%;
  text-align: left;
  font-style: normal;
  font-family: Georgia, 'Times New Roman', serif;
}

.newsResume p a {
  color: black;
  text-decoration: underline;
}